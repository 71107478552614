import Image from "next/image";
import React, { useState } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";

const CardMobile = ({ item, onClick }) => {
  const [firstView, setFirstView] = useState("text");

  return (
    <div className="relative w-full flex flex-col items-center">
      {/* Video Container */}
      <div className={`w-full ${firstView === "video" ? "z-50" : "z-10"} relative`}>
        <div className="rounded-2xl overflow-hidden aspect-video">
          {item.poster && (
            <Image
              width={0}
              height={0}
              className="object-cover h-full w-full"
              onClick={() => {
                setFirstView("video");
              }}
              src={item.poster}
              alt="poster"
              layout="responsive"
              decoding="async"
              priority
              sizes="100vw"
            />
          )}
        </div>
      </div>

      {/* Text Container */}
      <div
        onClick={() => setFirstView("text")}
        className={`h-96 absolute mx-6 top-44 bg-white bg-opacity-90 p-6 rounded-2xl shadow-2xl flex flex-col justify-center ${firstView === "text" ? "z-50" : "z-10"}`}
      >
        <p onClick={onClick} className={`text-xl font-semibold text-gray-900 mb-4 line-clamp-3 hover:cursor-pointer hover:underline`}>
          {item?.title || "Default Title"}
        </p>
        <p className="text-gray-700 leading-relaxed line-clamp-5">
          {item?.description || "Default description text to fill in placeholder space."}
        </p>
        <div onClick={onClick} className="ml-auto mt-auto">
          <MdOutlineNavigateNext color={"#6E6E6E"} size={40} />
        </div>
      </div>
    </div>
  );
};

export default CardMobile;
