import Image from "next/image";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";

const VideoCard = ({ program, showOpen = false, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showPoster, setShowPoster] = useState(true);
  const [firstView, setFirstView] = useState("text");
  const router = useRouter();

  return (
    <div
      className="relative w-full flex items-center justify-between"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Video Container */}
      <div
        onClick={() => setFirstView("video")}
        className={`rounded-2xl overflow-hidden aspect-video w-full md:w-[600px] lg:w-[1000px] ${firstView === "video" ? "z-50" : "z-10"}`}
      >
        {showPoster && program?.poster ? (
          <div
            onClick={() => program?.videoUrl && setShowPoster(false)}
            className="relative w-full h-full"
          >
            <Image
              width={0}
              height={0}
              sizes="100vh"
              className="object-cover h-full w-full"
              src={program?.poster}
              alt="poster"
              layout="responsive"
              priority
            />
            <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 flex items-center justify-center rounded-2xl" />
          </div>
        ) : (
          <iframe
            onMouseEnter={() => setFirstView("video")}
            width="100%"
            height="100%"
            src={program?.videoUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        )}
      </div>
      {/* Text Container */}
      <div
        onMouseEnter={() => setFirstView("text")}
        className={`absolute md:right-0 md:h-[90%] md:w-1/2 bg-white bg-opacity-90 p-6 rounded-2xl shadow-2xl flex flex-col justify-center ${firstView === "text" ? "z-50" : "z-10"}`}
      >
        {showOpen ? (
          <p
            onClick={onClick}
            className={`text-xl font-semibold text-gray-900 mb-4 line-clamp-4 hover:cursor-pointer hover:underline`}
          >
            {program?.title || "Default Title"}
          </p>
        ) : (
          <p
            className={`text-xl font-semibold text-gray-900 mb-4 line-clamp-4`}
          >
            {program?.title || "Default Title"}
          </p>
        )}
        <p className="text-gray-700 leading-relaxed line-clamp-4 lg:line-clamp-8">
          {program?.description ||
            "Default description text to fill in placeholder space."}
        </p>
        {showOpen && (
          <div
            onClick={onClick}
            className="absolute bottom-6 right-6 hover:cursor-pointer"
          >
            <MdOutlineNavigateNext color={"#6E6E6E"} size={40} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
